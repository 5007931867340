<template>
  <el-card class="box-card" style="height: 300px">
    <div slot="header" class="clearfix">
      <img src="@/assets/img/icon/speaker.png" alt="" class="recommand-icon">
      <span>站点公告</span>
    </div>
    <div class="text item" style="height: 20vh;">
      <el-scrollbar style="width: 100%; height: 100%;">
        <el-row>
          <span v-html="content" />
        </el-row>
      </el-scrollbar>
    </div>
  </el-card>
</template>

<script>
import { getSiteNotice } from '@/api/content'

export default {
  name: 'SiteNotice',
  data() {
    return {
      content: ''
    }
  },
  created() {
    getSiteNotice().then(resp => {
      if (resp.code === 0) {
        this.content = resp.data
      }
    }).catch(error => {
      this.$message.error(error.message)
    }).finally(() => {
    })
  },
  methods: {}
}
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .clearfix span {
    font-size: 15px;
    position: absolute;
    bottom: 8px;
    left: 35px;
  }
}

.item {
  height: 25px;
  margin-top: 4px;
  margin-bottom: 16px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /*行数*/
  -webkit-box-orient: vertical;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.clearfix {
  position: relative;
}
.clearfix span {
  font-size: 20px;
  position: absolute;
  bottom: 8px;
  left: 35px;
}
.box-card {
  width: 100%;
}

.recommand-icon {
  width: 30px;
}
</style>
