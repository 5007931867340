<template>
  <el-row>
    <el-row class="movie-list">
      <el-row>
        <el-col :md="12">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
            <site-notice />
          </el-row>
        </el-col>
        <el-col :md="12">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
            <el-carousel :interval="3000" height="300px">
              <el-carousel-item v-for="(item, index) in carouselList" :key="index">
                <router-link target="_blank" :to="`/video/${item.videoId}`">
                  <img class="carousel_image_type" :src="item.coverUrl" alt="img">
                </router-link>
              </el-carousel-item>
            </el-carousel>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
    <el-scrollbar style="width: 100%; height: 100vh">
      <el-row class="movie-list">
        <el-row
          v-if="dataList.length !== 0"
          v-infinite-scroll="load"
          infinite-scroll-disabled="loading"
          infinite-scroll-distance="10"
        >
          <!--电影列表-->
          <el-col :md="24">
            <el-col v-for="(item, index) in dataList" :key="index" :md="6" :sm="12" :xs="12">
              <video-card :video="item" />
            </el-col>
          </el-col>
        </el-row>
        <el-row v-else class="not-result">
          <el-col :span="12" :offset="6">
            <img src="@/assets/img/icon/not-collection.png">
            <div>推荐数据正在计算中</div>
          </el-col>
        </el-row>
      </el-row>
    </el-scrollbar>
  </el-row>
</template>

<script>
import VideoCard from '@/components/card/VideoCard'
import SiteNotice from '@/components/card/SiteNotice'
import { getBannerVideo, videoRecommend } from '@/api/video'

export default {
  name: 'Index',
  components: { VideoCard, SiteNotice },
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      nextId: 0,
      dataList: [],
      loading: false,
      max: 0,
      carouselList: []
    }
  },
  created() {
    this.videoRecommendWrapper(this.nextId)
    this.getHotVideoWrapper()
    // this.initServerSendEvent()
  },
  mounted() {
    // 当窗口宽度改变时获取屏幕宽度
    window.onresize = () => {
      return () => {
        window.screenWidth = document.body.clientWidth
        this.screenWidth = window.screenWidth
      }
    }
  },
  methods: {
    videoRecommendWrapper(nextId) {
      videoRecommend(nextId).then(resp => {
        if (resp.code === 0) {
          this.loading = false
          const respData = resp.data
          if (respData.length === 0) {
            this.$message(
              {
                message: '已经到底啦~~~',
                type: 'info',
                duration: 1000
              }
            )
            return
          }

          for (const item of respData) {
            this.dataList.push(item)
          }
          this.nextId++
          this.$message(
            {
              message: '已加载新数据~~~',
              type: 'info',
              duration: 1000
            }
          )
        } else {
          this.$message(
            {
              message: '获取数据失败, 请重新刷新页面',
              type: 'warning',
              duration: 1000
            }
          )
        }
      }).catch(error => {
        this.$message(
          {
            message: error.message,
            type: 'error',
            duration: 1000
          }
        )
      }).finally(() => {
      })
    },
    load() {
      this.loading = true
      setTimeout(() => {
        this.videoRecommendWrapper(this.nextId)
      }, 1000)
    },
    getHotVideoWrapper() {
      getBannerVideo().then(resp => {
        if (resp.code === 0) {
          this.carouselList = resp.data
        }
      }).catch(error => {
        this.$message(
          {
            message: error.message,
            type: 'error',
            duration: 1000
          }
        )
      }).finally(() => {
      })
    },
    initServerSendEvent() {
      if (typeof (EventSource) !== 'undefined') {
        const sseUrl = process.env.VUE_APP_SERVER_URL + '/api/data/video/hot'
        const source = new EventSource(sseUrl)
        source.addEventListener('test', function(e) {
          console.log(e)
        })

        const that = this
        source.onmessage = function(event) {
          console.log('update sse data')
          const dataList = JSON.parse(event.data)
          if (dataList.length !== 0) {
            that.carouselList = dataList
          }
        }
      } else {
        this.$message(
          {
            message: '抱歉，你的浏览器不支持 SSE...',
            type: 'warning',
            duration: 1000
          }
        )
      }
    }
  }
}
</script>

<style scoped>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}

.not-result {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.carousel_image_type{
  width: 100%;
}
</style>
